.navbar {
	height: 10vh;
	display: flex;
	justify-content: space-between;
	align-items: center;
	z-index: 5;
	width: 95vw;
	background-color: black;
	margin: 0 auto;
}

.navbar h3 {
	color: white;
	font-size: 1rem;
	margin: 20px;
}

.logo {
	height: 10vh;
}

.sign-out-button {
	cursor: pointer;
	transform: translateX(20px);
}

.sign-out {
	padding: 8px;
	border: 2px solid white;
	transition: 0.2s;
}

.sign-out:hover {
	color: black;
	background-color: white;
}

.search-sign-out {
	color: white;
	display: flex;
	align-items: center;
}

.buttons {
	height: 20px;
	transform: translateX(-55vw);
}
