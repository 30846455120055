.list_item {
    width: 100%;
    height: 100%;
    padding: 10px;
    transition: 0.1s;
    border-radius: 2%;
    text-align: left;
}

.list_item:hover {
    background-color: #efb758;
}